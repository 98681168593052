<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="box">
    <el-container>
      <el-header>
        <div style="font-size:40px;color: #fff;width: 100%;text-align: center;line-height: 76px;">{{ title }}</div>
        <el-button class="btn" @click="open" type="primary" icon="el-icon-setting" round>进入后台</el-button>
      </el-header>
      <el-main>
        <el-row>
          <el-col :span="7">
            <div class="left">
              <div class="lTop">
                <div class="title1">进货数据</div>
                <div style="margin:10px 0 0 20px">
                  <el-table ref="table1" :header-cell-style="{ background: '#1653B0', color: '#1ADAF8' }"
                    :cell-style="{ color: '#fff' }" :data="purchases" height="200" style="width: 87%;">
                    <el-table-column prop="sellerName" label="商户名称" width="120">
                    </el-table-column>
                    <el-table-column prop="productName" label="产品" width="70">
                    </el-table-column>
                    <el-table-column prop="quantity" label="进货数量(kg)" width="120" >
                    </el-table-column>
                    <el-table-column prop="purchaseDate" label="进货日期" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </div>

              <div class="lBot">
                <div class="title1">
                  协议详情
                </div>
                <ZXZZT :zxtopData="zxtopData" v-if="zxtopData.datay.length>0"></ZXZZT>
              </div>
              <div class="lMid">
                <div class="title1" style="line-height:56px">
                  月度菜品交易量(吨)
                </div>
                <ZXT :zxtData="monthProductQuantities" v-if="monthProductQuantities.length>0"></ZXT>
                <!-- <div class="title1" style="line-height:56px">
                  菜品来源TOP3(吨)
                </div> -->
                <!-- <div class="top1">top1</div>
                <div class="top2">top2</div>
                <div class="top3">top3</div>
                <HXZZT :top1Data="top1Data"></HXZZT>
                <HXZZT :top1Data="top2Data"></HXZZT>
                <HXZZT :top1Data="top3Data"></HXZZT> -->
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="mid">
              <div class="MTop">
                <div style="color:#fff;margin: 10px 0 ;">
                  <span style="margin-left:80px">总商户数</span>
                  <span style="margin-left:80px">溯源商户数</span>
                  <span style="margin-left:100px">当日上市数量(吨)</span>
                  <span style="margin-left:60px">累计上市数量(吨)</span>
                </div>
                <div style="display:flex">
                  <div class="lNum">
                    <div style="color:#fff;margin-left:40px;width: 60px;">{{ zshNum }}</div>
                    <div style="color:#fff;margin-left:40px">/</div>
                    <div style="color:#FFED73;margin-left:40px">{{ syNum }}</div>
                  </div>
                  <div class="rNum">
                    <div style="color:#fff;margin-left:40px;width: 60px;">{{ dayaNum }}</div>
                    <div style="color:#fff;margin-left:40px">/</div>
                    <div style="color:#FFED73;margin-left:40px">{{ allNum }}</div>
                  </div>
                </div>
              </div>
              <div class="MMid">
                <div class="demo-image__preview">
                  <el-image style="width: 800px; height: 470px" :src="bigSrc" :preview-src-list="srcList">
                  </el-image>
                </div>
                <!-- <img style="width:800px;height:470px" :src='bigSrc' alt="javascript"> -->
              </div>
              <div class="MBot">
                <div class="title1">实时出货数据</div>
                <div style="margin:10px 0 0 40px">
                  <el-table ref="table2" :header-cell-style="{ background: '#1653B0', color: '#1ADAF8' }"
                    :cell-style="{ color: '#fff' }" :data="tableData1" height="200" style="width: 95%;">
                    <el-table-column prop="sellerName" label="商户名称" width="140">
                    </el-table-column>
                    <el-table-column prop="booth" label="摊位号" width="140">
                    </el-table-column>
                    <el-table-column prop="productName" label="产品" width="80">
                    </el-table-column>
                    <el-table-column prop="quantity" label="数量(公斤)" width="140">
                    </el-table-column>
                    <el-table-column prop="dateText" label="日期">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="right">
              <div class="rTop">
                <span class="title">快检数据</span>
                <span class="title" style="margin-left: 20px;">检测总数：</span>
                <span class="title" style="margin:0;color:#FDCEB5">{{ quickTestTotal }}</span>
                <span class="title" >合格率：</span>
                <span class="title" style="margin:0;color:#FDCEB5">{{ quickTestPassRate }}%</span>
                <!-- <YBP :ybpData="ybpData"></YBP> -->
                <div style="margin:10px 0 0 10px">
                  <el-table ref="table3" :header-cell-style="{ background: '#1653B0', color: '#1ADAF8' }"
                    :cell-style="{ color: '#fff' }" :data="quickTestData" height="220" style="width: 85%;">
                    <el-table-column prop="merchantName" label="商户名称" width="120">
                    </el-table-column>
                    <el-table-column prop="spotItem" label="抽检项目" width="100">
                    </el-table-column>
                    <el-table-column prop="productName" label="产品" width="100">
                    </el-table-column>
                    <el-table-column prop="testResult" label="检测结果"  width="90">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div class="rMid">
                <div class="title">市场基本信息</div>
                <el-button class="ckyy" @click="dialogVisible = true" type="text">查看营业执照</el-button>
                <el-dialog class="dialog" title="营业执照" :visible.sync="dialogVisible" width="36%"
                  :before-close="handleClose">
                  <div>
                    <img style="margin:14px 0 0 30px;width:580px;height: 700px ;" :src="xxSrc" alt="暂无图片">
                  </div>
                </el-dialog>
                <!-- <img style="margin:14px 0 0 30px;width:380px;height: 240px ;" :src="xxSrc" alt="暂无图片"> -->
                <div style="margin:30px 0 0 10px">
                  <div style="display:flex;border: 1px solid #fff;border-bottom: none;width: 400px;height: 80px;">
                    <div style="line-height:80px;color:#fff;font-size:20px;border-right:1px solid #fff;width:140px;padding:0 10px;">统一信用代码</div>
                    <div style="line-height:80px;color:#fff;font-size:20px; padding-left: 20px;">{{businessLicenseCode}}</div>
                  </div>
                  <div style="display:flex;border: 1px solid #fff;width: 400px;height: 80px;">
                    <div style="line-height:80px;color:#fff;font-size:20px;border-right:1px solid #fff;padding:0 10px;">食品安全管理员</div>
                    <div style="line-height:80px;color:#fff;font-size:20px;padding-left: 20px;">{{securityManager}}</div>
                  </div>
                  <!-- <el-table ref="table4" :header-cell-style="{ background: '#1653B0', color: '#1ADAF8' }"
                    :cell-style="{ color: '#fff' }" :data="tableData2" height="240" style="width: 85%;">
                    <el-table-column prop="name" label="市场名称" width="180">
                    </el-table-column>
                    <el-table-column prop="businessLicenseCode" label="统一信用代码" width="120">
                    </el-table-column>
                    <el-table-column prop="securityManager" label="食品安全管理员" width="100">
                    </el-table-column>
                  </el-table> -->
                </div>
              </div>
              <div class="rBot">
                <div class="title">交易量季度排行榜(吨)</div>
                <div class="thr3">
                  <div v-for="(item, index) in jdph"
                    style="width:340px;line-height:40px;margin:10px 0 0 30px;color:#fff;font-size:20px;border-bottom:1px solid #fff;display: flex;">
                    <div>{{ item.xh }}</div>
                    <div style="margin-left:20px;width: 250px;overflow: hidden; text-overflow: ellipsis;">
                      {{ item.sellerName }}</div>
                    <div style="color: #29C4D1;">{{ item.quantity }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { bigList } from "@/api/getData";
import moment from "moment";
// import HXZZT from "@/components/echarts/hxzzt.vue"
import ZXZZT from "@/components/echarts/zxzzt.vue"
import ZXT from "@/components/echarts/zxt.vue"
// import YBP from "@/components/echarts/ybp.vue"
export default {
  nam: "index",
  components: {
    // HXZZT,
    ZXZZT,
    ZXT
    // YBP
  },
  data() {
    return {
      //标题
      nameUrl: '',
      //标题
      title: '',
      //左一
      purchases: [],
      //左二
      zxtopData: {
        datax: ['销售者', '溯源商户', '安全协议', '承诺书'],
        datay: []
      },
      // top1Data: {
      //   id: 1,
      //   datax: [],
      //   datay: []
      // },
      // top2Data: {
      //   id: 2,
      //   datax: [],
      //   datay: []
      // },
      // top3Data: {
      //   id: 3,
      //   datax: [],
      //   datay: []
      // },
      //左三
      monthProductQuantities: [],

      //中一
      zshNum: 0,
      syNum: 0,
      dayaNum: 0,
      allNum: 0,
      //中二
      // bigSrc: '',
      bigSrc: '',
      srcList: [],
      //中三
      tableData1: [],
      //右一
      quickTestTotal: 0,
      quickTestPassRate: 0,
      quickTestData: [],
      //扇形图的数据
      // ybpData: [
      //   { value: 0, name: '合格' },
      //   { value: 0, name: '不合格' }
      // ],
      //右二
      xxSrc: '',
      businessLicenseCode:'',
      securityManager:'',
      //右三
      jdph: [],
      //给后端传送的测试数据
      cesiid: '',
      //地利账号
      // cesiid: '858A247AFFFD469A82EF59AAA93FF97E',
      //盛发账号
      // cesiid: "6E285E75106F42C593C62B80A26B97AA",
      //弹窗
      dialogVisible: false
    }
  },
  methods: {
    //初始函数
    init() {
      //标题解码名称
      let a = decodeURI(this.$store.state.nameUrl);
      this.nameUrl = decodeURI(a);
      this.cesiid=this.nameUrl
      // console.log(this.nameUrl);
      //获取数据
      this.toData()
      //表格滚动效果
      this.scrollFun()
    },
    //表格自动滚动
    scrollFun() {
      let table1 = this.$refs.table1;
      let divData1 = table1.bodyWrapper;
      clearInterval(this.timers1);
      this.timers1 = setInterval(() => {
        divData1.scrollTop += 1;
        if (divData1.clientHeight + divData1.scrollTop >= divData1.scrollHeight) {
          divData1.scrollTop = 0
        }
      }, 50);
      let table2 = this.$refs.table2;
      let divData2 = table2.bodyWrapper;
      clearInterval(this.timers2);
      this.timers2 = setInterval(() => {
        divData2.scrollTop += 1;
        if (divData2.clientHeight + divData2.scrollTop >= divData2.scrollHeight) {
          divData2.scrollTop = 0
        }
      }, 50);
      let table3 = this.$refs.table3;
      let divData3 = table3.bodyWrapper;
      clearInterval(this.timers3);
      this.timers3 = setInterval(() => {
        divData3.scrollTop += 1;
        if (divData3.clientHeight + divData3.scrollTop >= divData3.scrollHeight) {
          divData3.scrollTop = 0
        }
      }, 50);
      let table4 = this.$refs.table4;
      let divData4 = table4.bodyWrapper;
      clearInterval(this.timers4);
      this.timers4 = setInterval(() => {
        divData4.scrollTop += 1;
        if (divData4.clientHeight + divData4.scrollTop >= divData4.scrollHeight) {
          divData4.scrollTop = 0
        }
      }, 50);
    },
    //获取数据
    toData() {
      //调接口，处理数据
      this.proData()
      //获取整点时间
      var nowTemp = new Date().getTime() //获取当前时间戳
      var nexthoursTemp = new Date(moment().add(1, 'hours').format('YYYY-MM-DD H:mm:ss').substr(0, 14) + ':00:00').getTime() //获取下个小时整点的时间戳
      var residueTemp = nexthoursTemp - nowTemp //当前距离下个小时整点的时间戳
      //执行定时任务
      setTimeout(() => {
        this.proData()
        //刷新方法
        //次时0点 执行下个小时整点刷新
        setInterval(() => {
          this.proData()
          //刷新方法
        }, 1000 * 60 * 60);
      }, residueTemp)
    },
    //调接口，处理数据
    proData() {
      bigList(this.cesiid)
        .then((res) => {
          console.log('----------', res);
          //标题
          this.title = res.name;
          //左一
          this.purchases = res.purchases;
          //左二
          this.zxtopData.datay = []
          this.zxtopData.datay.push(res.sellerNameDocNum)
          this.zxtopData.datay.push(res.sellerNameTrace)
          this.zxtopData.datay.push(res.agreementNum)
          this.zxtopData.datay.push(res.commitmentNum)
          // this.zxtopData.datax = []
          // this.zxtopData.datay = []
          // res.productTransactions.forEach((item) => {
          //   this.zxtopData.datax.push(item.productName)
          //   this.zxtopData.datay.push(item.quantity.toFixed(0))
          // });
          // this.top1Data.datax = []
          // this.top1Data.datay = []
          // res.productSources[0].forEach((item) => {
          //   this.top1Data.datax.push(item.cityName)
          //   this.top1Data.datay.push(item.quantity.toFixed(0))
          // });
          // this.top2Data.datax = []
          // this.top2Data.datay = []
          // res.productSources[1].forEach((item) => {
          //   this.top2Data.datax.push(item.cityName)
          //   this.top2Data.datay.push(item.quantity.toFixed(0))
          // });
          // this.top3Data.datax = []
          // this.top3Data.datay = []
          // res.productSources[2].forEach((item) => {
          //   this.top3Data.datax.push(item.cityName)
          //   this.top3Data.datay.push(item.quantity.toFixed(0))
          // });
          //左三
          this.monthProductQuantities=res.monthProductQuantities
          //中一
          this.zshNum = res.sellerNumTotal;
          this.syNum = res.sellerNameTrace;
          this.dayaNum = res.entryNumToday.toFixed(0);
          this.allNum = res.entryNumTotal.toFixed(1);
          //中二
          let a = 'https://np.zhihuianxian.com' + res.planPath
          this.bigSrc = a
          this.srcList = []
          this.srcList.push(a)
          //中三
          this.tableData1 = res.shipments
          //右一
          this.quickTestTotal = res.quickTestTotal
          this.quickTestPassRate = res.quickTestPassRate * 100
          this.quickTestData = res.quickTestData
          // this.overNum = res.quickTestTotal
          // this.ybpData[0].value = res.quickTestPass
          // this.ybpData[1].value = res.quickTestUnqualified
          //右二
          this.businessLicenseCode = res.businessLicenseCode
          this.securityManager = res.securityManager
          let b = 'https://np.zhihuianxian.com' + res.licensePath
          this.xxSrc = b
          //右三
          res.sellerTransactions.forEach((item, i) => {
            item.xh = i + 1
          })
          this.jdph = res.sellerTransactions
        })
        .catch((err) => {
          // console.log(err);
        })
    },
    //进入后台按钮
    open() {
      // window.location.href = 'https://np.zhihuianxian.com/Management_Home/index';
      window.parent.postMessage('gotoHome', '*')
    },
    //弹窗
    handleClose(done) {
      this.dialogVisible = false
    }
  },
  mounted() {
    this.init();
  },
  //销毁界面，关闭websocket
  beforeDestroy() {
    clearInterval();
    clearTimeout()
  },

}
</script>

<style scoped>
::v-deep.el-header {
  height: 100px !important;
  padding: 0;
  background: url(../assets/bg_title.png) no-repeat;
}

::v-deep.el-card__body,
.el-main {
  padding: 0;
}

.btn {
  position: absolute;
  top: 3%;
  left: 89%;
  /* width: 154px; */
  /* height: 66px; */
  border: none;
  font-size: 20px;
  color: #fff;
  /* background: url(../assets/btn_setup@2x.png); */
}

/* .btn :hover{
  color: #52FDFD;
  background-color: none;
} */
.left {
  /* border: 1px solid red; */
  padding-left: 60px;
}

.lTop {
  height: 270px;
  margin-bottom: 40px;
  background: url(../assets/bg_purchase@2x.png) no-repeat;
}

.lMid {
  height: 368px;

  background: url(../assets/bg_top3_from@2x.png) no-repeat;
}

.lBot {
  height: 240px;
  background: url(../assets/bg_top3_sale@2x.png) no-repeat;
  margin-bottom: 40px;
}

.top1 {
  color: #FDCEB5;
  font-size: 20px;
  position: absolute;
  top: 362px;
  left: 80px;
  font-weight: 600;
}

.top2 {
  color: #FDCEB5;
  font-size: 20px;
  position: absolute;
  top: 455px;
  font-weight: 600;
  left: 80px;
}

.top3 {
  color: #FDCEB5;
  font-size: 20px;
  position: absolute;
  top: 549px;
  font-weight: 600;
  left: 80px;
}

/* 表格样式 */
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

::v-deep.el-table tr {
  background-color: transparent !important;
}

::v-deep.el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

.el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: rgba(0, 0, 0, 0) !important;
}

/*滚动条*/
::v-deep .el-table .el-table__fixed-right-patch {
  width: 0px !important;
}

::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 0 !important;
}

::v-deep.el-table th.gutter {
  display: none;
  width: 0
}

::v-deep.el-table colgroup col[name='gutter'] {
  display: none;
  width: 0;
}

::v-deep.el-table__body {
  width: 100% !important;
}

/* 标题样式 */
.title1 {
  color: #1CE2FF;
  font-size: 18px;
  margin: 0 0 0 50px;
  line-height: 40px;
  font-weight: 600;
}

.title {
  color: #1CE2FF;
  font-size: 18px;
  margin: 0 0 0 30px;
  line-height: 30px;
  font-weight: 600;
}

.lNum {
  background: url('../assets/bg_num_store@2x.png');
  height: 78px;
  width: 310px;
  margin: 0 0 0 40px;
  line-height: 70px;
  font-size: 40px;
  display: flex;
}

.rNum {
  background: url('../assets/bg_num_market@2x.png');
  height: 78px;
  width: 350px;
  margin: 0 0 0 40px;
  line-height: 70px;
  font-size: 40px;
  display: flex;
}

.MMid {
  margin: 50px 0 0 0;
  width: 800px;
  height: 470px;
  /* box-shadow: 4px 4px 15px #f00; */
}

.MBot {
  width: 802px;
  height: 262px;
  background: url('../assets/bg_shipment\ @2x.png');
  margin: 45px 0 0 0;
  /* background-color: #009CFF;
  opacity: 70%;
  border: #009CFF 1px dashed; */
}

.right {
  /* border: 1px solid red; */
  margin-left: 60px;
}

.rTop {
  height: 260px;
  margin-bottom: 45px;
  background: url(../assets/bg_quickdate@2x.png) no-repeat;
}

.thr3 {
  height: 250px;
  overflow-y: auto;
  white-space: nowrap;
  overflow-x: hidden;
}

.thr3::-webkit-scrollbar {
  display: none;
}

.rMid {
  height: 290px;
  margin-bottom: 45px;
  background: url(../assets/bg_ranking@2x.png) no-repeat;
}

.rBot {
  height: 310px;
  background: url(../assets/bg_market@2x.png) no-repeat;
}

.ckyy {
  position: absolute;
  top: 32%;
  left: 87%;
  color: #fff;
  font-size: 16px;
}
</style>