<!-- 纵向柱状图 -->
<template>
    <div id="zzt" style="width:442px;height: 220px;"></div>
</template>
<script>
export default {
    name: "HelloEcharts",
    props: {
        zxtopData: {
            type: Object,
            default: {},
        }
    },
    data() {
        return {
            myChart: null
        };
    },

    // watch: {
    //     zxtopData: {
    //         handler(e, o) {
    //             // console.log(e);
    //             if (e.datax.length > 0 && e.datay.length > 0) {
    //                 this.initEcharts(e)
    //             }
    //         },
    //         deep: true,
    //         immediate: true
    //     }
    // },
    mounted() {
        this.initEcharts(this.zxtopData)
    },
    methods: {
        initEcharts(e) {
            this.myChart = this.$echarts.init(document.getElementById('zzt'));
            const option = {
                xAxis:
                {
                    type: 'category',
                    data: e.datax,
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    },
                    axisTick: {		//x轴刻度线
                        show: true
                    },
                    splitLine: { //网格线
                        show: true,
                        lineStyle: {
                            color: ['#12457b'],
                            type: 'dashed'
                        }
                    },
                    interval: 200
                },

                series: [
                    {
                        data: e.datay,
                        type: 'bar',
                        barWidth: '30%',
                        itemStyle: {
                            normal: {
                                //这里是重点
                                color: function (params) {
                                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                    var colorList = ['#DC79DE', '#FFBBAE', '#F77C3E', '#00e3ff'];
                                    return colorList[params.dataIndex]
                                }
                            }
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                color: '#fff'
                            }
                        }
                        // grid:{
                        //     left:'400'
                        // }
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
};
</script>
<style scoped>

</style>
