import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nameUrl:'',//标题编码
  },
  getters: {
  },
  mutations: {
    //标题编码
    nameUrl(state,nameUrl){
      state.nameUrl=nameUrl
    }
  },
  actions: {
  },
  modules: {
  }
})
