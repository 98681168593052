<!-- 纵向柱状图 -->
<template>
    <div id="zxt" style="width:442px;height: 300px;"></div>
</template>
<script>
export default {
    name: "HelloEcharts",
    props: {
        zxtData: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            myChart: null
        };
    },

    // watch: {
    //     zxtopData: {
    //         handler(e, o) {
    //             // console.log(e);
    //             if (e.datax.length > 0 && e.datay.length > 0) {
    //                 this.initEcharts(e)
    //             }
    //         },
    //         deep: true,
    //         immediate: true
    //     }
    // },
    mounted() {
        this.initEcharts(this.zxtData)
    },
    methods: {
        initEcharts(e) {
            let datas = e
            console.log('datas', datas);
            this.myChart = this.$echarts.init(document.getElementById('zxt'));
            const option = {
                grad: {
                    // x:0,
                    // y:0,
                    // x2:0,
                    // y2:100
                    // containLabel: true
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}'
                },
                legend: {
                    left: 'center',
                    textStyle:{
                        color:'#fff'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff',//坐标轴的具体的颜色
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    },
                    splitLine: { //网格线
                        show: true,
                        lineStyle: {
                            color: '#12457b',
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        name: datas[0].name,
                        data: datas[0].data,
                        type: 'line'
                    },
                    {
                        name: datas[1].name,
                        data: datas[1].data,
                        type: 'line'
                    },
                    {
                        name: datas[2].name,
                        data: datas[2].data,
                        type: 'line'
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
};
</script>
<style scoped>

</style>
