<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
	data(){
		return{
			
		}
	},
  beforeCreate(){
    //标题编码存入vuex
    // let nameUrl=window.location.href.substring(28)
    let commaIndex = window.location.href.indexOf("=");
    let nameUrl = window.location.href.substring(commaIndex + 1);
    // console.log('1111',nameUrl);
    this.$store.commit('nameUrl',nameUrl)
  },
	methods:{
		
	}
}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
#app{
  background: url('./assets/bg@2x.jpg') no-repeat;
  width: 100%;
  height: 1080px;
}
</style>
